<template lang="pug">
footer
  .content.has-text-centered
    | Copyright 2019-{{currentYear}} Nutworth Ltd. All rights reserved.
    br
    router-link(to="/about/privacy") Privacy Policy
    | &nbsp;-&nbsp;
    router-link(to="/about/terms") Terms of Service
    br
    a.contact-link(href="mailto:support@nutworth.com")
      b-icon(size='is-small' icon="envelope")
      span Contact us
</template>

<script>
import dayjs from '@/dayjs'

export default {
  data() {
    return {
      currentYear: dayjs().format('YYYY')
    }
  }
}
</script>

<style lang="sass" scoped>
a, a:visited
  color: hsl(118, 60%, 28%)
  &:hover, &:active
    text-decoration: underline
.contact-link
  display: flex
  align-items: center
  justify-content: center
  .icon
    margin-right: 0.5rem
footer
  margin-top: 2.5rem
  padding: 2rem
  color: #121715
  </style>
