<template lang="pug">
.welcome
  h1.title.has-text-centered Processing...
  b-loading(:is-full-page="true" :can-cancel="false" :active.sync="needsPayment")
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'WelcomeView',
  data: () => ({
    needsPayment: null
  }),
  computed: {
    ...mapState('authentication', ['user'])
  },
  watch: {
    user: {
      handler(user) {
        if (user.paymentState === 'pending' && user.plan !== 'starter') {
          // Waiting for server-side function to run and init billing
          this.needsPayment = true
        } else if (user.paymentState === 'paid' || user.plan === 'starter') {
          this.$router.push('/portfolio/data').catch(() => {})
          this.$buefy.toast.open('Welcome')
        } else if (user.paymentState === 'failed') {
          this.$router.push('/billing/update')
          this.$buefy.toast.open('Your most recent payment failed.')
        }
      },
      immediate: true,
      deep: true
    }
  },
  metaInfo: {
    title: 'Processing...'
  }
}
</script>
<style lang="sass" scoped>
.welcome
  flex: 1 1 auto
  display: flex
  flex-direction: column
  padding-top: 4rem
  max-width: 600px
  margin: 0 auto
</style>
