<template lang="pug">
  .auth-page
    b-loading(:is-full-page="true" :active.sync="isProcessing" :can-cancel="false" v-if="actionType == 'verifyEmail' || actionType == 'recoverEmail'")
    .container.content-wrapper
      .columns
        .column.is-6.is-offset-3
          h1.title(v-if="actionType == 'resetPassword'") Reset your Password
          h1.title(v-if="actionType == 'recoverEmail'") Recovering your Email...
          h1.title(v-if="actionType == 'verifyEmail'") Verifying your Email...
          b-message(title="You're offline" type="is-warning" :closable="false" v-if="!networkOnLine" data-test="offline-instruction")
            | You're currently offline. You'll need to connect to the internet
            | to continue.
      .columns(v-if="networkOnLine")
        .column.is-6.is-offset-3(v-if="actionType == 'verifyEmail' || actionType == 'recoverEmail'")
          b-message(title="Error" type="is-warning" aria-close-label="Close message" v-if="authError") {{ authError }}
        .column.is-6.is-offset-3(v-if="actionType == 'resetPassword'")
          .box
            .content
              password-input(label="New Password" autocomplete="new-password" v-model="form.password" @enter="changePassword")
              b-button.is-fullwidth.is-medium.is-primary(:disabled="formIsInvalid" :loading="isResetting" @click="changePassword" data-test="login-btn")
                | Save Password
          b-message(title="Error" type="is-warning" aria-close-label="Close message" v-if="authError") {{ authError }}
    about-footer
</template>

<script>
import { mapState, mapActions } from 'vuex'
import firebase from 'firebase/app'
import { ToastProgrammatic as Toast } from 'buefy'
import firestore from '@/firebase/async-firestore'
import PasswordInput from '@/components/PasswordInput'
import AboutFooter from '@/components/AboutFooter'

export default {
  components: {
    PasswordInput,
    AboutFooter
  },
  data() {
    return {
      // resetPassword or verifyEmail or recoverEmail
      actionType: this.$route.query.mode,
      actionToken: this.$route.query.oobCode,
      form: {
        password: ''
      },
      authError: null,
      isProcessing: false,
      isResetting: false,
      isApplied: false
    }
  },
  computed: {
    ...mapState('app', ['networkOnLine']),
    ...mapState('authentication', ['user']),
    formIsInvalid() {
      if (this.form.password.length < 8) return true
      return false
    }
  },
  watch: {
    user: {
      handler(user) {
        if (user === undefined) {
          return
        }
        if (this.isApplied) {
          return
        }
        if (this.actionType === 'verifyEmail') {
          this.confirmEmail()
        }
        if (this.actionType === 'recoverEmail') {
          this.recoverEmail()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('authentication', ['login', 'onEmailVerified']),
    async confirmEmail() {
      try {
        this.authError = null
        this.isProcessing = true
        this.isApplied = true
        await firebase.auth().applyActionCode(this.actionToken)
        Toast.open('Your email address has been verified.')
        if (this.user) {
          await this.onEmailVerified()
          this.$router.push('/portfolio/data').catch(() => {})
        } else {
          this.$router.push('/login').catch(() => {})
        }
      } catch {
        this.isProcessing = false
        this.authError = `Could not verify your email address. This can happen if
        you've already verified it or your code has expired.`
      }
    },
    async recoverEmail() {
      this.authError = null
      this.isProcessing = true
      this.isApplied = true
      try {
        const info = await firebase.auth().checkActionCode(this.actionToken)
        const recoveredEmail = info.data.email
        if (this.user) {
          // Update in firestore first
          await (await firestore())
            .collection('users')
            .doc(this.user.id)
            .set(
              {
                email: recoveredEmail,
                emailVerified: true
              },
              { merge: true }
            )
        }
        // Update in auth
        await firebase.auth().applyActionCode(this.actionToken)
        // Log the user out and tell them to log in again
        await firebase.auth().signOut()
        Toast.open(
          'Your email address has been recovered. Please sign in again'
        )
        this.$router.push('/login').catch(() => {})
      } catch (error) {
        console.error(error)
        this.isProcessing = false
        this.authError = `Could not recover your email address. Please contact support@nutworth.com`
      }
    },
    changePassword() {
      if (this.form.password.length < 8) {
        return
      }
      this.authError = null
      this.isResetting = true
      firebase
        .auth()
        .confirmPasswordReset(this.actionToken, this.form.password)
        .then(() => {
          Toast.open('You can now sign in.')
          this.$router.push('/login').catch(() => {})
        })
        .catch(err => {
          this.isResetting = false
          this.authError = err.message
        })
    }
  },
  metaInfo() {
    switch (this.actionType) {
      case 'verifyEmail':
        return { title: 'Verifying Email' }
      case 'resetPassword':
        return { title: 'Resetting Password' }
      case 'recoverEmail':
        return { title: 'Email Recovery' }
      default:
        return { title: 'Account Management' }
    }
  }
}
</script>

<style lang="sass" scoped>
.auth-page
  flex: 1 1 auto
  display: flex
  flex-direction: column
.content-wrapper
  flex-grow: 1
  padding: 2rem 1rem 1rem
  width: 100%
</style>
