<template lang="pug">
.wrapper
  .maybe-mobile-section
    .login-page.width-constrained
      .heading-section
        h1.title Welcome back

      .login-form(v-if="networkOnLine")
        .sso-options
          .button.is-white.google-auth.auth-button(@click="loginWithGoogle()")
            span.icon-container
              img(alt="Google" src="@/assets/img/sso/google.svg")
            span.text Sign in with Google
        .or-continue
          | Or continue with
        form.email-form
          b-field(label="Email address")
            b-input(type="email" v-model="form.email" :required="true" autocomplete="email" name="email")
          password-input(v-model="form.password" @enter="login" :validate="false")
          a.has-text-right.forgot-prompt(@click="resetPasswordPrompt") Reset my password
          b-message(title="Error" type="is-warning" aria-close-label="Close message" v-if="loginError") {{ loginError }}
          b-button.is-fullwidth.is-primary(:disabled="formIsInvalid" :loading="isProcessing" @click="login" data-test="login-btn")
            | Sign in
      .offline-message(v-else)
        b-message(title="You're offline" type="is-warning" :closable="false" data-test="offline-instruction")
          | You're currently offline. You'll need to connect to the internet to continue.
    .centered-footer.width-constrained
      router-link.signup-link(:to="{name: 'sign_up'}") Need an account?
      a.contact-link(href="mailto:support@nutworth.com" target="_blank")
        b-icon(size='is-small' icon="envelope")
        span Get help

  .flex-div
  about-footer.is-hidden-touch
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash'
import firebase from 'firebase/app'
import { ToastProgrammatic as Toast } from 'buefy'
import PasswordInput from '@/components/PasswordInput'
import AboutFooter from '@/components/AboutFooter'
import { actionTaken } from '@/misc/analytics'

export default {
  name: 'LoginView',
  components: {
    PasswordInput,
    AboutFooter
  },
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      loginError: null,
      isProcessing: false
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle']),
    formIsInvalid() {
      if (!this.form.email.match(/@/)) return true
      if (this.form.password.length < 8) return true
      return false
    }
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          const redirectUrl = isNil(this.$route.query.redirectUrl)
            ? '/portfolio/data'
            : this.$route.query.redirectUrl
          this.$router.push(redirectUrl).catch(() => {})
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('authentication', ['resetPassword', 'signInWithGoogle']),
    login() {
      this.loginError = null
      this.isProcessing = true
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(() => {
          actionTaken('login', 1)          
          Toast.open('Welcome back!')
        })
        .catch(err => {
          this.loginError = err.message
          this.isProcessing = false
        })
    },
    loginWithGoogle() {
      this.loginError = null
      this.isProcessing = true
      this.signInWithGoogle({})
        .then(() => {
          actionTaken('login', 1)          
          Toast.open('Welcome back!')
        })
        .catch(err => {
          this.loginError = err.message
          this.isProcessing = false
        })
    },
    resetPasswordPrompt() {
      this.$buefy.dialog.prompt({
        message: 'Please enter your email address',
        inputAttrs: {
          placeholder: 'Email address',
          value: this.form.email
        },
        onConfirm: value => {
          this.resetPassword(value)
            .then(() => {
              Toast.open({
                message: 'Check your email for reset instructions',
                duration: 5000
              })
            })
            .catch(err => {
              Toast.open({ message: `Error: ${err.message}`, duration: 5000 })
            })
        }
      })
    }
  },
  metaInfo: {
    title: 'Sign in',
    meta: [
      {
        name: 'description',
        content: `Sign in to nutworth, an easy to use net worth tracker.`
      }
    ]
  }
}
</script>

<style lang="sass" scoped>
.maybe-mobile-section
  display: flex
  flex-direction: column
  background: #fafafa
  align-items: center
.width-constrained
  max-width: 450px
  width: 100%
.login-page
  display: flex
  flex-direction: column
  justify-content: center
  flex-grow: 1
  padding-top: 3rem
.heading-section
  text-align: center
  width: 100%
  position: relative
  height: 3rem
  margin-bottom: 1rem
.sso-options
  display: flex
  flex-direction: column
  align-items: center
  margin: 0 2.5rem 0.5rem
.offline-message, .error-message
  margin: 0 1rem
.or-continue
  display: flex
  justify-content: center
  align-items: center
  color: #666
  margin: 1rem .5rem
  &:before, &:after
    content: " "
    height: 1px
    flex-grow: 1
    background: #666
    margin: 0 1rem
.email-form
  margin: 1.5rem
  ::v-deep label
    font-weight: normal
  .button
    margin-top: 1rem
.wrapper
  display: flex
  flex-direction: column
  flex-grow: 1
.flex-div
  flex-grow: 1
.forgot-prompt
  font-size: 0.9rem
  display: block
  margin-top: -0.35rem
  margin-bottom: 0.5rem
.centered-footer
  font-size: 0.9rem
  text-align: center
  padding: 1rem 1.5rem
  display: flex
  justify-content: space-between
.contact-link
  color: #666
  display: flex
  align-items: center
  &:hover, &:active
    text-decoration: underline
  .icon
    margin-right: 0.5rem
</style>
