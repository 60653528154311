<template lang="pug">
.wrapper
  .maybe-mobile-section
    .signup-page.width-constrained
      .heading-section
        h1.title Get started
      .signup-form(v-if="networkOnLine")
        .sso-options
          .button.is-white.google-auth.auth-button(@click="signUpWithGoogle()")
            span.icon-container
              img(alt="Google" src="@/assets/img/sso/google.svg")
            span.text Sign up with Google
        .or-continue
          | Or continue with
        form.email-form
          b-field(label="Email address")
            b-input(type="email" name="email" autocomplete="email" v-model="form.email" :required="true")
          password-input(v-model="form.password" :required="true" autocomplete="new-password")
          .plan-chooser
            span.plan-label(@click="showPlanInfo = true")
              | Plan
              b-icon(icon="question-circle")
            textual-select(v-model="form.plan" :options="planOptions")
            a.coupon-button(v-if="!showCouponInput && form.plan !== 'starter'" @click="showCouponForm")
              | I have a coupon
            .price-info(v-if="form.plan !== 'starter'")
              | {{ +activePlan.price[currencyIdx] | formatCurrency(form.currency.toUpperCase()) }}/{{activePlan.period}}
          .coupon-adder(v-if="showCouponInput && form.plan !== 'starter'")
            b-field(label="Coupon")
              b-input(v-model="couponInput" :readonly="isCheckingCoupon" autofocus @keydown.native.enter="checkCoupon" ref="couponInput" name="coupon")
            b-button.button.is-secondary(@click="checkCoupon" :loading="isCheckingCoupon") Apply
            strong.coupon-error(v-if="couponBad")
              | Sorry, that's not a valid coupon code.
            strong.coupon-description(v-if="couponDescription")
              | {{couponDescription}} Discount will be applied at checkout.
          b-message(v-if="signUpError" title="Error" type="is-warning" aria-close-label="Close message") {{ signUpError }}
          b-button.is-fullwidth.is-primary(:disabled="formIsInvalid" :loading="isProcessing" @click="doRegistration")
            | Create Account
      .offline-message(v-else)
        b-message(title="You're offline" type="is-warning" :closable="false" v-if="!networkOnLine" data-test="offline-instruction")
          | You're currently offline. You'll need to connect to the internet to continue.
    .centered-footer.width-constrained
      | By signing up, you accept our
      br
      router-link(to="/about/terms" target="_blank") Terms of Service
      | &nbsp;and&nbsp;
      router-link(to="/about/privacy" target="_blank") Privacy Policy.
    b-modal(:active.sync="showPlanInfo" has-modal-card :can-cancel="['escape']" :trap-focus="true")
      .modal-card
        header.modal-card-head
          p.modal-card-title About our plans
          b-button(icon-left="times" @click="showPlanInfo = false")
        section.modal-card-body
          .content
            p
              | We offer both a paid "pro" plan as well as a free starter plan.
              | Our free plan comes with a few limitations:
            ul
              li Up to 8 unique holdings
              li
                | Up to 100 entries
                ul
                  li Over 8 years at one update a month!
              li Can only export data in USD
              li No sharing features
            p
              | Our paid plans come with full access to all features and support
              | the ongoing development of our service.
        footer.modal-card-foot
          b-button.is-primary(@click="showPlanInfo = false") Got it!
  .flex-div
  about-footer.is-hidden-touch
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash'
import firestore from '@/firebase/async-firestore'
import NewPlans from '@/misc/plan-info-updated'
import PasswordInput from '@/components/PasswordInput'
import TextualSelect from '@/components/textual/Select'
import AboutFooter from '@/components/AboutFooter'
import { formatCurrency } from '@/misc/filters'
import { actionTaken } from '@/misc/analytics'

const CURRENCIES = ['usd', 'aud']

export default {
  components: {
    AboutFooter,
    PasswordInput,
    TextualSelect
  },
  filters: {
    formatCurrency
  },
  data() {
    let currency = 'usd'
    if (this.$route.query.currency) {
      if (this.$route.query.currency === 'aud') currency = 'aud'
    } else if (navigator.language === 'en-AU') currency = 'aud'
    return {
      showPlanInfo: false,
      signUpError: null,
      isProcessing: false,
      showCouponInput: false,
      couponInput: '',
      isCheckingCoupon: false,
      couponBad: false,
      couponDescription: null,
      NewPlans,
      form: {
        email: '',
        password: '',
        termsAccepted: false,
        plan: this.$route.query.plan || 'starter',
        currency,
        coupon: null
      }
    }
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle']),
    activePlan() {
      return this.NewPlans.find(({ id }) => id === this.form.plan)
    },
    currencyIdx() {
      return CURRENCIES.indexOf(this.form.currency)
    },
    planOptions() {
      return this.NewPlans.map(({ id, name }) => ({
        value: id,
        name
      }))
    },
    formIsInvalid() {
      if (!this.form.email.match(/@/)) return true
      if (this.form.password.length < 8) return true
      if (!this.form.plan) return true
      return false
    }
  },
  watch: {
    user: {
      handler(user) {
        if (!isNil(user)) {
          this.$router.push('/welcome').catch(() => {})
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('authentication', ['register', 'signInWithGoogle']),
    showCouponForm() {
      this.showCouponInput = true
      this.$nextTick(() => {
        this.$refs.couponInput.focus()
      })
    },
    async signUpWithGoogle() {
      if (this.isProcessing) {
        return
      }
      this.signUpError = null
      this.form.termsAccepted = true
      this.isProcessing = true
      this.signInWithGoogle({
        plan: this.form.plan,
        currency: this.form.currency,
        coupon: this.form.coupon
      })
        .then(() => {
          // Log conversion event to analytics
          actionTaken('sign_up', 1)         
        })
        .catch(err => {
          this.signUpError = err.message
          this.isProcessing = false
        })
    },
    async doRegistration() {
      this.signUpError = null
      this.form.termsAccepted = true
      this.isProcessing = true
      this.register(this.form)
        .then(() => {
          // Log conversion event to analytics    
          actionTaken('sign_up', 1)         
        })
        .catch(err => {
          this.signUpError = err.message
          this.isProcessing = false
        })
    },
    async checkCoupon() {
      this.couponBad = false
      this.couponDescription = null
      this.form.coupon = null
      if (this.couponInput === '') {
        return
      }
      this.isCheckingCoupon = true
      const db = await firestore()
      const doc = await db
        .collection('coupons')
        .doc(this.couponInput.toLocaleLowerCase())
        .get()
      if (!doc.exists || doc.data().expired) {
        this.couponBad = true
      } else {
        this.form.coupon = doc.id
        this.couponDescription = doc.data().description
      }
      this.isCheckingCoupon = false
    }
  },
  metaInfo: {
    title: 'Create an account',
    meta: [
      {
        name: 'description',
        content: `Create a free or paid account on nutworth to begin tracking your net worth with ease.`
      }
    ]
  }
}
</script>

<style lang="sass" scoped>
.maybe-mobile-section
  display: flex
  flex-direction: column
  background: #fafafa
  align-items: center
.width-constrained
  max-width: 450px
  width: 100%
.signup-page
  display: flex
  flex-direction: column
  justify-content: center
  flex-grow: 1
  padding-top: 3rem
.heading-section
  text-align: center
  width: 100%
  position: relative
  height: 3rem
  margin-bottom: 1rem
.sso-options
  display: flex
  flex-direction: column
  align-items: center
  margin: 0 2.5rem 0.5rem
.offline-message, .error-message
  margin: 0 1rem
.or-continue
  display: flex
  justify-content: center
  align-items: center
  color: #666
  margin: 1rem .5rem
  &:before, &:after
    content: " "
    height: 1px
    flex-grow: 1
    background: #666
    margin: 0 1rem
.email-form
  margin: 1.5rem
  ::v-deep label
    font-weight: normal
  .button
    margin-top: 1rem
.centered-footer
  font-size: 0.9rem
  text-align: center
  margin: 1rem
.plan-chooser
  display: flex
  flex-wrap: wrap
  align-items: center
  .plan-label
    flex-grow: 1
    min-width: 40%
    .icon
      color: #666
    &:hover
      cursor: pointer
      .icon
        color: #43ae43
  .coupon-button
    min-width: 60%
    font-size: 0.9rem
  .price-info
    text-align: right
    margin-top: 0.5rem
    flex-grow: 1
.modal-card
  margin-left: 0.5rem !important
  margin-right: 0.5rem !important
  width: calc(100% - 1rem)
.modal-card-foot
  display: flex
  justify-content: flex-end
.coupon-adder
  margin-top: 0.75rem
  flex-wrap: wrap
  align-items: center
  .label
    min-width: 100%
.coupon-adder
  display: flex
  flex-wrap: wrap
  .field
    flex-grow: 1
    margin-right: 0.5rem
    position: relative
    top: -2px
.coupon-error
  color: red
  min-width: 100%
.coupon-description
  color: green
  min-width: 100%
.wrapper
  display: flex
  flex-direction: column
  flex-grow: 1
.flex-div
  flex-grow: 1
</style>
