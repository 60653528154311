<template lang="pug">
  b-field(:label="label")
    b-input(
      name="password"
      :value="value"
      :autocomplete="autocomplete"
      @input="onInput"
      type="password"
      password-reveal
      :disabled='disabled'
      :required='required'
      :minlength='minlength'
      validation-message="Passwords must be at least 8 characters long."
      @keydown.native.enter="onEnter")
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Password'
    },
    autocomplete: {
      type: String,
      default: 'current-password'
    },
    value: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    minlength() {
      if (this.validate) {
        return 8
      }
      return 0
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    onEnter($event) {
      this.$emit('enter', $event)
    }
  }
}
</script>
<style lang="sass" scoped>
// Lastpass fix - move the password reveal to the left
::v-deep
  input
    padding-left: 2.5rem !important
    padding-right: calc(0.625em - 1px) !important
  span.icon
    padding-left: 0.25rem
    right: auto
    left: 0
</style>
